var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', _vm._g({}, _vm.$listeners), [_c('input', {
    ref: "inputRef",
    staticClass: "tw-hidden",
    attrs: {
      "type": "file",
      "accept": _vm.accept,
      "multiple": _vm.multiple
    },
    on: {
      "change": _setup.onFilePicked
    }
  }), _vm._v(" "), _vm._t("activator", null, null, {
    on: _setup.on
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };